var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ArrowDir, BaseUrl, LocationType } from "../client";
import pinSVG from '../svgs/pin_arrow.svg';
var Tick = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: 0;\n  height: 0;\n  left: 12px;\n  z-index: 30;\n  border: 0.5rem solid transparent;\n  ", "\n"], ["\n  position: relative;\n  width: 0;\n  height: 0;\n  left: 12px;\n  z-index: 30;\n  border: 0.5rem solid transparent;\n  ", "\n"])), function (p) {
    return p.top
        ? "top: -40px; " + "border-top-color: #fff;"
        : "border-bottom-color: #fff;";
});
var Bubble = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  bottom: 0.5rem;\n  pointer-events: none;\n  transition: opacity 250ms ease-in-out;\n  &.is-hidden {\n    opacity: 0;\n    pointer-events: none;\n\n    & * {\n      pointer-events: none;\n      cursor: pointer;\n    }\n  }\n  &.is-active {\n    z-index: 62;\n  }\n"], ["\n  position: relative;\n  bottom: 0.5rem;\n  pointer-events: none;\n  transition: opacity 250ms ease-in-out;\n  &.is-hidden {\n    opacity: 0;\n    pointer-events: none;\n\n    & * {\n      pointer-events: none;\n      cursor: pointer;\n    }\n  }\n  &.is-active {\n    z-index: 62;\n  }\n"])));
//   ${(p) => (p.onClick ? ":hover { cursor: pointer; }" : "")}
var BubbleText = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #fff;\n  border-radius: 4px;\n  padding: 8px;\n  position: relative;\n  pointer-events: all;\n  box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.2);\n  font-family: \"Manrope\", sans-serif;\n  right: ", ";\n  ", ";\n  ", "\n  z-index: 5;\n"], ["\n  background: #fff;\n  border-radius: 4px;\n  padding: 8px;\n  position: relative;\n  pointer-events: all;\n  box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.2);\n  font-family: \"Manrope\", sans-serif;\n  right: ", ";\n  ", ";\n  ", "\n  z-index: 5;\n"])), function (p) { return (p.left ? "calc(100% - 40px)" : "8px"); }, function (p) { return (p.top ? "transform: translateY(calc(-100% - 39px - 1rem));" : ""); }, function (p) { return (p.onClick ? ":hover { cursor: pointer; }" : ""); });
var LocationCircle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  height: 40px;\n  width: 40px;\n  background-color: ", ";\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 2; \n  color: #ececec;\n  transition: all 250ms ease-in-out;\n  &.is-active {\n    background-color: white;\n    color: ", ";\n    z-index: 60;\n  }\n  &.is-hidden {\n    opacity: 0;\n    pointer-events: none;\n  }\n  ", "\n"], ["\n  position: relative;\n  height: 40px;\n  width: 40px;\n  background-color: ", ";\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 2; \n  color: #ececec;\n  transition: all 250ms ease-in-out;\n  &.is-active {\n    background-color: white;\n    color: ", ";\n    z-index: 60;\n  }\n  &.is-hidden {\n    opacity: 0;\n    pointer-events: none;\n  }\n  ", "\n"])), function (p) { return typeToColor(p.type); }, function (p) { return typeToColor(p.type); }, function (p) { return (p.onClick ? "cursor: pointer;" : ""); });
var Arrow = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 61;\n  display: flex;\n  align-content: center;\n  justify-content: center;\n\n  ::after {\n    border-radius: 50%;\n    background-color: ", ";\n    display: block;\n    height: 15px;\n    width: 15px;\n    text-align: center;\n    content: url(", ");\n  }\n\n  ", ";\n"], ["\n  position: absolute;\n  z-index: 61;\n  display: flex;\n  align-content: center;\n  justify-content: center;\n\n  ::after {\n    border-radius: 50%;\n    background-color: ", ";\n    display: block;\n    height: 15px;\n    width: 15px;\n    text-align: center;\n    content: url(", ");\n  }\n\n  ", ";\n"])), function (p) { return typeToColor(p.type); }, pinSVG, function (p) {
    switch (p.direction) {
        case ArrowDir.Up:
            return "top: -6px;";
        case ArrowDir.Down:
            return "bottom: -6px; transform: rotate(180deg);";
        case ArrowDir.Left:
            return "left: -6px; transform: rotate(-90deg);";
        case ArrowDir.Right:
            return "right: -6px; transform: rotate(90deg);";
        default:
            return "";
    }
});
var Line1 = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-weight: 700;\n  font-size: 15px;\n"], ["\n  font-weight: 700;\n  font-size: 15px;\n"])));
function typeToColor(lt) {
    switch (lt) {
        case LocationType.Venue:
        case LocationType.Region:
            return "#4AA27C";
        case LocationType.Activity:
            return "#4A76A2";
        case LocationType.Attraction:
            return "#DC8A00";
    }
}
var Line2 = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-weight: 600;\n  font-size: 12px;\n  color: ", ";\n"], ["\n  font-weight: 600;\n  font-size: 12px;\n  color: ", ";\n"])), function (p) { return typeToColor(p.type); });
var IconContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  & > svg {\n    max-width: 26px;\n    max-height: 26px;\n  }\n"], ["\n  & > svg {\n    max-width: 26px;\n    max-height: 26px;\n  }\n"])));
export function LocationPin(_a) {
    var x = _a.x, y = _a.y, left = _a.left, top = _a.top, type = _a.type, line1 = _a.line1, line2 = _a.line2, img = _a.img, showInfo = _a.showInfo, onClick = _a.onClick, arrow = _a.arrow, isActive = _a.isActive, isVisible = _a.isVisible;
    var _b = useState(false), hovering = _b[0], setHovering = _b[1];
    var _c = useState(String), svgResponse = _c[0], setSVG = _c[1];
    var showText = isVisible && (showInfo || hovering);
    var realOnClick;
    if (onClick) {
        realOnClick = function (e) {
            e.stopPropagation();
            onClick();
        };
    }
    function mouseEnter() {
        setHovering(true);
    }
    var mouseLeave = function (e) {
        setHovering(false);
    };
    useEffect(function () {
        var iconUrl = BaseUrl + img;
        fetch(iconUrl)
            .then(function (response) { return response.text(); })
            .then(function (svg) {
            setSVG(svg);
        });
    });
    var xAdjusted = Math.max(Math.min(96.5, x), 3.5);
    var yAdjusted = Math.max(Math.min(96.5, y), 3.5);
    return (_jsx("div", __assign({ style: {
            position: "absolute",
            left: "calc(" + xAdjusted + "% - 20px) ",
            top: "calc(" + yAdjusted + "% - 20px)",
            pointerEvents: isVisible ? 'all' : 'none',
        } }, { children: _jsxs("div", __assign({ style: { position: "relative" } }, { children: [_jsxs(LocationCircle, __assign({ className: hovering ? "is-active" : isActive ? "is-active" : isVisible ? "" : "is-hidden", type: type, onClick: realOnClick, onMouseEnter: mouseEnter, onMouseLeave: mouseLeave }, { children: [arrow && _jsx(Arrow, { direction: arrow, type: type }, void 0), svgResponse && (_jsx(IconContainer, { dangerouslySetInnerHTML: { __html: svgResponse } }, void 0))] }), void 0), _jsxs(Bubble, __assign({ className: showText ? (isActive || hovering) ? "is-active" : "" : "is-hidden" }, { children: [_jsx(Tick, { top: top }, void 0), _jsxs(BubbleText, __assign({ left: left, top: top, onClick: realOnClick }, { children: [_jsx(Line1, { children: line1 }, void 0), line2 && _jsx(Line2, __assign({ type: type }, { children: line2.toUpperCase() }), void 0)] }), void 0)] }), void 0)] }), void 0) }), void 0));
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
