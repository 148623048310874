import { createClient } from "./client/createClient";
import { ApolloClient, InMemoryCache } from "@apollo/client";
var _a = document.location, protocol = _a.protocol, host = _a.host;
export var BaseUrl = protocol + "//" + host;
export var client = createClient({
    fetcher: function (_a, fetch, qs) {
        var query = _a.query, variables = _a.variables;
        return fetch(BaseUrl + "/graphql/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ query: query, variables: variables }),
        }).then(function (r) { return r.json(); });
    },
});
export var LocationType;
(function (LocationType) {
    LocationType["Venue"] = "venue";
    LocationType["Attraction"] = "attraction";
    LocationType["Activity"] = "activity";
    LocationType["Region"] = "region";
})(LocationType || (LocationType = {}));
export var ArrowDir;
(function (ArrowDir) {
    ArrowDir["Up"] = "up";
    ArrowDir["Down"] = "down";
    ArrowDir["Left"] = "left";
    ArrowDir["Right"] = "right";
})(ArrowDir || (ArrowDir = {}));
export var LocationQuery = {
    id: 1,
    name: 1,
    xCord: 1,
    yCord: 1,
    type: 1,
    region: 1,
    iconUrl: 1,
    arrow: 1,
    slug: 1,
    locationDistances: {
        distance: 1,
        destinationId: 1,
    },
    labelPosition: 1,
};
export var apolloClient = new ApolloClient({
    uri: BaseUrl + "/graphql/",
    cache: new InMemoryCache(),
});
