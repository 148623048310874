var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { mobileBP } from "../MainLayout";
var GREEN = '#4aa27c'; // better location?
export var ButtonContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  gap: var(--prm);\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  gap: var(--prm);\n"])));
export var FilledButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  color: #ffffff;\n  font-size: 15px;\n  font-weight: bold;\n  border: 2px solid ", ";\n  margin-right: 30px;\n  padding: 10px 20px 10px 20px;\n  border-radius: 4px;\n  transition: all 200ms ease-in-out;\n\n  :hover {\n    cursor: pointer;\n    background-color: white;\n    color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  color: #ffffff;\n  font-size: 15px;\n  font-weight: bold;\n  border: 2px solid ", ";\n  margin-right: 30px;\n  padding: 10px 20px 10px 20px;\n  border-radius: 4px;\n  transition: all 200ms ease-in-out;\n\n  :hover {\n    cursor: pointer;\n    background-color: white;\n    color: ", ";\n  }\n"])), GREEN, GREEN, GREEN);
export var Button = styled.a(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline-block;\n  text-decoration: none;\n  background-color: #ffffff;\n  color: #4aa27c;\n  font-size: 15px;\n  font-weight: bold;\n  border: 2px solid ", ";\n  padding: 10px 20px 10px 20px;\n  border-radius: 4px;\n  cursor: pointer;\n  text-transform: uppercase;\n  transition: all 200ms ease-in-out;\n\n  :hover {\n    background-color: ", ";\n    color: white;\n  }\n\n  @media screen and (min-width: ", ") {\n    padding: 8px 16px 8px 16px;\n  }\n"], ["\n  display: inline-block;\n  text-decoration: none;\n  background-color: #ffffff;\n  color: #4aa27c;\n  font-size: 15px;\n  font-weight: bold;\n  border: 2px solid ", ";\n  padding: 10px 20px 10px 20px;\n  border-radius: 4px;\n  cursor: pointer;\n  text-transform: uppercase;\n  transition: all 200ms ease-in-out;\n\n  :hover {\n    background-color: ", ";\n    color: white;\n  }\n\n  @media screen and (min-width: ", ") {\n    padding: 8px 16px 8px 16px;\n  }\n"])), GREEN, GREEN, mobileBP);
var templateObject_1, templateObject_2, templateObject_3;
