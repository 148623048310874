var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useState, useRef, useEffect } from "react";
import styled from "styled-components";
import FsLightbox from "fslightbox-react";
import icon360 from "../../../svgs/icons/360-icon.svg";
var ThumbWithIcon = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  display: block;\n"], ["\n  position: relative;\n  display: block;\n"])));
var PrimaryThumbWithIcon = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  display: block;\n  height: 380px;\n  width: 100%;\n"], ["\n  position: relative;\n  display: block;\n  height: 380px;\n  width: 100%;\n"])));
var GallerySlider = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  overflow-x: scroll;\n  overflow-y: hidden;\n  max-width: 100%;\n  gap: 15px;\n  margin-top: 15px;\n"], ["\n  display: flex;\n  overflow-x: scroll;\n  overflow-y: hidden;\n  max-width: 100%;\n  gap: 15px;\n  margin-top: 15px;\n"])));
export default function ImageGallery(_a) {
    var gallery = _a.gallery, galleryId = _a.galleryId;
    var _b = useState({
        toggler: false,
        sourceIndex: 0,
    }), lightboxController = _b[0], setLightboxController = _b[1];
    function openLightboxOnSlide(index) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            sourceIndex: index,
        });
    }
    var galleryImages = useMemo(function () {
        if (!gallery) {
            return [{ thumb: "", lightbox: "", lightboxType: null, is360: false }];
        }
        return gallery.map(function (_a) {
            var embed = _a.embed, rawEmbed = _a.rawEmbed, imageThumbnail = _a.imageThumbnail, embedHtml = _a.embedHtml, thumbnail = _a.thumbnail, image = _a.image, url = _a.url;
            if (image) {
                return {
                    thumb: imageThumbnail === null || imageThumbnail === void 0 ? void 0 : imageThumbnail.rendition.url,
                    lightbox: image.rendition.url,
                    lightboxType: "image",
                    is360: false,
                };
            }
            else if (embedHtml) {
                //360 video
                return {
                    thumb: thumbnail === null || thumbnail === void 0 ? void 0 : thumbnail.rendition.url,
                    lightbox: _jsx("div", { dangerouslySetInnerHTML: { __html: embedHtml } }, void 0),
                    lightboxType: null,
                    is360: true,
                };
            }
            else if (rawEmbed) {
                var youtubeThumb = JSON.parse(rawEmbed !== null && rawEmbed !== void 0 ? rawEmbed : "");
                if (youtubeThumb.thumbnail_url !== undefined) {
                    return {
                        thumb: youtubeThumb.thumbnail_url,
                        lightbox: url,
                        lightboxType: "youtube",
                        is360: false,
                    };
                }
            }
            return {
                thumb: undefined,
                lightbox: "",
                lightboxType: null,
                is360: false,
            };
        });
    }, [gallery]);
    var _c = useState(0), primaryGalleryIndex = _c[0], setPrimaryGalleryIndex = _c[1];
    var primaryGalleryImage = galleryImages[primaryGalleryIndex];
    var _d = useState(0), sidebarWidth = _d[0], setSidebarWidth = _d[1];
    var sliderRef = useRef(null);
    useEffect(function () {
        var handleResize = function () {
            if (sliderRef && sliderRef.current) {
                setSidebarWidth(sliderRef.current.getBoundingClientRect().width);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
    });
    var primary = function () {
        var styles = {
            backgroundColor: "grey",
            backgroundImage: "url(" + (primaryGalleryImage === null || primaryGalleryImage === void 0 ? void 0 : primaryGalleryImage.thumb) + ")",
            backgroundSize: "cover",
            backgroundPosition: "center",
        };
        return (_jsx(PrimaryThumbWithIcon, __assign({ style: styles, onClick: function () { return openLightboxOnSlide(primaryGalleryIndex); } }, { children: primaryGalleryImage.is360 ? (_jsx("img", { src: icon360, style: {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "60px",
                    transform: "translate(-50%,-50%)",
                } }, void 0)) : (_jsx(_Fragment, {}, void 0)) }), void 0));
    };
    var secondary = function () {
        if (galleryImages.length < 2) {
            return null;
        }
        var width = '25%';
        if (sidebarWidth) {
            width = (sidebarWidth - 40) / 4 + "px";
        }
        return galleryImages.map(function (image, index) {
            if (image.is360) {
                return (_jsxs(ThumbWithIcon, { children: [!image.thumb ? (_jsx("div", { className: "", style: {
                                backgroundColor: "grey", minHeight: "100%", width: width
                            } }, void 0)) : (_jsx("img", { src: image.thumb, style: {
                                width: width,
                                height: '100%'
                            }, onClick: function () { return setPrimaryGalleryIndex(index); } }, void 0)), _jsx("img", { src: icon360, style: {
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                width: "35px",
                                transform: "translate(-50%,-50%)",
                            }, onClick: function () { return setPrimaryGalleryIndex(index); } }, void 0)] }, void 0));
            }
            if (!image.thumb) {
                return (_jsx("div", { style: { backgroundColor: "grey", width: width, }, onClick: function () { return setPrimaryGalleryIndex(index); } }, void 0));
            }
            return (_jsx("img", { style: { width: width }, src: image.thumb, onClick: function () { return setPrimaryGalleryIndex(index); } }, void 0));
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(FsLightbox, { toggler: lightboxController.toggler, sources: galleryImages.map(function (gi) { return gi.lightbox; }), types: galleryImages.map(function (gi) { return gi.lightboxType; }), sourceIndex: lightboxController.sourceIndex }, galleryId), _jsxs("div", { children: [primary(), _jsx(GallerySlider, __assign({ ref: sliderRef }, { children: secondary() }), void 0)] }, void 0)] }, void 0));
}
var templateObject_1, templateObject_2, templateObject_3;
