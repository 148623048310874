var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var MainSummaryContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: var(--prm-lg) var(--prm) 0 var(--prm);\n"], ["\n  padding: var(--prm-lg) var(--prm) 0 var(--prm);\n"])));
export var PricingTable = styled.table(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 1px solid #999;\n  border-collapse: collapse;\n  margin-bottom: 40px;\n"], ["\n  border: 1px solid #999;\n  border-collapse: collapse;\n  margin-bottom: 40px;\n"])));
export var TableRow = styled.tr(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border: 1px solid #999;\n"], ["\n  border: 1px solid #999;\n"])));
export var TableHeading = styled.th(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: \"Manrope\", sans-serif;\n  font-size: 16px;\n  font-weight: 700;\n  border: 1px solid rgb(0 0 0 / 10%);\n  padding: 18px 14px;\n  letter-spacing: 0.08px;\n  width: 92px;\n"], ["\n  font-family: \"Manrope\", sans-serif;\n  font-size: 16px;\n  font-weight: 700;\n  border: 1px solid rgb(0 0 0 / 10%);\n  padding: 18px 14px;\n  letter-spacing: 0.08px;\n  width: 92px;\n"])));
export var TableData = styled.td(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-family: \"Manrope\", sans-serif;\n  border: 1px solid rgb(0 0 0 / 10%);\n  padding: 18px 14px;\n  text-align: center;\n  font-size: 16px;\n  font-weight: 300;\n  letter-spacing: 0.08px;\n"], ["\n  font-family: \"Manrope\", sans-serif;\n  border: 1px solid rgb(0 0 0 / 10%);\n  padding: 18px 14px;\n  text-align: center;\n  font-size: 16px;\n  font-weight: 300;\n  letter-spacing: 0.08px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
