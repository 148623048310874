var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var MainSummaryImage = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: auto;\n"], ["\n  width: 100%;\n  height: auto;\n"])));
export var GalleryContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-left: 40px;\n  padding-bottom: 4px;\n  width: 561px;\n"], ["\n  padding-left: 40px;\n  padding-bottom: 4px;\n  width: 561px;\n"])));
export var GallerySecondaryImage = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 136.23px;\n  margin-right: 4px;\n"], ["\n  width: 136.23px;\n  margin-right: 4px;\n"])));
export var GalleryMainImage = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
